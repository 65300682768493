@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
.dragging {
    opacity: 0.5;
}

.step-arrow-nav {
    .nav {
        background-color: $white;

        .nav-link {
            border-radius: 0;
            border-width: 1px;
            border-style: solid;
            border-color: $primary;
            // background-color: #d6d6d6;
            position: relative;
            font-weight: $font-weight-normal;
            color: $primary;
            height: 36px;

            &::before {
                content: '';
                position: absolute;
                border: 7px solid transparent;
                right: -14px;
                top: 50%;
                transform: translateY(-50%);
            }

            &.done {
                background-color: $primary;
                color: $white;
                // text-align: center;
                height: 36px;
                &::before {
                    border-left-color: transparent;
                }
            }

            &.active {
                background-color: $primary;
                color: $white;
                box-shadow: none;

                &::before {
                    border-left-color: $primary;
                }
            }
        }

        .nav-item {
            &:last-child {
                .nav-link {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: 766px) {
    .instructor-col {
        border-left: 1px solid #ccc;
        padding-left: 10px; /* Adjust this value as needed */
    }
}

.lms-accordion {
    .accordion-button {
        background-color: #d5e6f9 !important;
    }
}
